@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.App {
  background-color: #000000;
  min-height: 100vh;
}

.logo-wrapper img {
  transition: transform 0.3s ease;
}

.logo-wrapper:hover img {
  transform: scale(1.1);
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Add any additional custom styles here */