@tailwind base;
@tailwind components;
@tailwind utilities;
@import './fonts.css';

body {
  margin: 0;
  font-family: 'Neue Metana', sans-serif;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-color: #000000;
  color: #ffffff;
  min-height: 100vh;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@keyframes iridescent-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.holographic-border {
  position: relative;
  z-index: 1;
  padding: 3px; /* Add padding to accommodate the thicker border */
}

.holographic-border::before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: linear-gradient(
    45deg,
    #00ffff,
    #ff00ff,
    #ffff00,
    #00ffff,
    #ff00ff,
    #ffff00
  );
  background-size: 200% 200%;
  animation: iridescent-shift 5s linear infinite;
  border-radius: inherit;
  z-index: -1;
  filter: blur(4px);
  opacity: 0.8;
}

.holographic-border::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  border-radius: inherit;
  z-index: -1;
}

header {
  padding-top: max(16px, env(safe-area-inset-top));
}

.bottom-content {
  padding-bottom: max(16px, env(safe-area-inset-bottom));
}
.pt-safe {
  padding-top: env(safe-area-inset-top);
}

@media (max-width: 768px) {
  .animated-background {
    background-size: 250% 250% !important;
  }
}