@font-face {
    font-family: 'Neue Metana';
    src: url('./NeueMetana-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Neue Metana';
    src: url('./NeueMetana-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }